import PageNotFound from "components/PageNotFound";
import Blog from "containers/Blog";
import Career from "containers/Career";
import LandingPage from "containers/LandingPage";
import TermsAndConditions from "containers/TermsAndConditions";
import { useRoutes } from "react-router-dom";

export default function Routes() {
  return useRoutes([
    {
      path: "/",
      element: <LandingPage />,
    },
    {
      path: "/blog/:id",
      element: <Blog />,
    },
    {
      path: "/career/:id",
      element: <Career />,
    },
    {
      path: "/terms-and-conditions",
      element: <TermsAndConditions />,
    },
    { path: "*", element: <PageNotFound /> },
  ]);
}
